import "babel-polyfill";

import "./css/normalize.css";
import "./wybieraj-legalne.scss";

const initCookies = () => {
  document.querySelector(".cookies .close").addEventListener("click", () => {
    document.querySelector(".cookies").classList.add("hidden");
  });
};

initCookies();
